<template>
  <div id="NewsList">
    <div id="YCF-top">
      <div class="top-titles">
        <h1>
          <b v-html="TopTitles.title"></b>
          <br />
          <small v-html="TopTitles.second_title"></small>
        </h1>
      </div>
      <div class="background-group">
        <div
          class="bg active"
          :style="{ backgroundImage: 'url(' + TopCovers[0] + ')' }"
          style="background-size: cover"
        ></div>
      </div>
    </div>

    <div id="YCF-Download-Center">
      <div class="row">
        <template v-for="file_type in download_files">
          <div :key="file_type.id">
            <span>{{ file_type.type }}</span>
            <ol>
              <li v-for="item in file_type.list" :key="item.id">
                <a :href="item.url">
                  {{ item.title }}
                  <i class="fas fa-download"></i>
                </a>
              </li>
            </ol>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { GetMetaData } from '@/common/meta.js'
export default {
  name: 'newslist',
  data() {
    return {
      TopTitles: {
        title: '線上資源',
        second_title: '認真、專業、負責、守信',
      },
      TopCovers: {
        0: '/img/index-header/download_cover.jpg',
      },
      download_files: [
        {
          type: '公司資訊',
          list: [
            {
              title: '鼎創工程_歷年工程實績V1090323.pdf',
              url: 'https://www.dingchong.com.tw/download/鼎創工程_歷年工程實績V1090323.pdf',
            },
          ],
        },
        {
          type: '公開資訊',
          list: [
            {
              title: '台北市禁限建範圍內列管案件管理及審核基準.pdf',
              url: 'https://www.dingchong.com.tw/download/台北市禁限建範圍內列管案件管理及審核基準.pdf',
            },
            {
              title:
                '建築物基礎開挖工程監測準則(中華民國大地工程學會)(106.03).pdf',
              url: 'https://www.dingchong.com.tw/download/建築物基礎開挖工程監測準則(中華民國大地工程學會)(106.03).pdf',
            },
            {
              title: '高雄市大眾捷運系統禁限建範圍內列管案件管理作業要點.pdf',
              url: 'https://www.dingchong.com.tw/download/高雄市大眾捷運系統禁限建範圍內列管案件管理作業要點.pdf',
            },
            {
              title:
                '高雄捷運禁限建法規與案例(高雄市政府捷運工程局)(100.5).pdf',
              url: 'https://www.dingchong.com.tw/download/高雄捷運禁限建法規與案例(高雄市政府捷運工程局)(100.5).pdf',
            },
            {
              title: '第02495章-特定條款.pdf',
              url: 'https://www.dingchong.com.tw/download/第02495章-特定條款.pdf',
            },
            {
              title: '第02495章節-監測儀器.pdf',
              url: 'https://www.dingchong.com.tw/download/第02495章節-監測儀器.pdf',
            },
            {
              title: '山坡地監測準則(中華民國大地工程學會)(106年).pdf',
              url: 'https://www.dingchong.com.tw/download/山坡地監測準則(中華民國大地工程學會)(106年).pdf',
            },
            {
              title:
                '232942_桃園市辦理大眾捷運系統禁建限建範圍內列管案件管理及審核基準條文.pdf',
              url: 'https://www.dingchong.com.tw/download/232942_桃園市辦理大眾捷運系統禁建限建範圍內列管案件管理及審核基準條文.pdf',
            },
          ],
        },
      ],
      meta_data: null,
    }
  },
  metaInfo() {
    return this.meta_data
  },
  mounted() {
    window.prerenderReady = true
  },
  created() {
    this.meta_data = GetMetaData(
      '線上資源',
      '各項工程監測系統規劃分析與評估、監測器材經銷業務、工程作業規劃與技術諮詢顧問。重視監測品質的專業監測工程公司，認真、專業、負責、守信是我們的座右銘。',
      '/img/index-header/download_cover.jpg'
    )
  },
}
</script>
